import SectionDisplayCard from "../components/SectionDisplayCard/SectionDisplayCard";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import NavbarDashboard from "../components/navbarDashboard/NavbarDashboard";
import ButtonEditPage from "../components/ButtonEditPage/ButtonEditPage";
import React, { useState } from "react";
import ProgressBar from "../components/ProgressBar/ProgressBar";
import "../components/SectionDisplayCard/SectionDisplayCard.css";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

function SectionDisplayPage() {
  const { id } = useParams();
  const { currentYear } = useParams();
  const { currentQuarter } = useParams();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const cardData = [
    {
      title: "Cover Story",
      content:
        "Break through event/anything that will improve visibility of PSG Brand/Ideas...",
    },
    {
      title: "Focus Articles",
      content:
        "Institution - Innovation/ Research/MOU/Ranking/Achievement of Department, Head & Faculty: Student/ Alumni: Special Events/CSR activity/ any other.",
    },
    {
      title: "Workshops",
      content:
        "Seminars/ Conferences/Training Programs conducted (Write up containing title,dates, organizers name & designation, who sponsored the program, guest details, topic, number& nature of target audience)",
    },
    {
      title: "Paper Presentations",
      content:
        "Significant in-person presentations in institutes of international/national repute by faculty and students",
    },
    {
      title: "Research Initiatives",
      content:
        "Title, details about the project, name & designation of investigator/s, duration, sanctioned amount, date and agency sanctioning the fund.One or two lines about the objective of the project can also be included.",
    },
    {
      title: "Awareness programs",
      content:
        "NSS/NCC/ CSR Activities(Like temple cleaning, school cleaning, projects taken up by individual institutions, departments, students. Include details like how long is the project, activities done, collaborations, outcomes, any associations, outcomes etc.)",
    },
    {
      title: "Achievements",
      content:
        "institution wise/student/ faculty with complete details – when, where, who, date [Note: Include only significant recognitions/awards received at the International/ National level]",
    },
    {
      title: "Alumni events",
      content: "Details about the alumni events conducted",
    },
    {
      title: "Student activities and Achievements",
      content:
        "(Student participation in major competitions and their achievements with complete details)",
    },
    {
      title: "International Programs",
      content:
        "Global Focus/ exchange programs(Visits of faculty/ Heads, International speakers)",
    },
    // {
    //   title: "Sports",
    //   content:
    //     "Sports (Only national & international participation with complete details & photograph)",
    // },
  ];
  const navigate = useNavigate();
  const handleClick = (title) => {
    navigate(
      `/representativeEdit/${id}/${currentYear}/${currentQuarter}/${title}`
    );
  };

  return (
    <div>
      <NavbarDashboard />
      <ButtonEditPage
        title="Progress"
        style={{ marginLeft: "5%", marginTop: "7%" }}
      />
      <div className="grid-container" style={{ marginTop: "1%" }}>
        {cardData.map((card, index) => (
          <SectionDisplayCard
            key={index}
            title={card.title}
            content={card.content}
            onClickCard={() => handleClick(card.title)}
          />
        ))}
      </div>
      {/* <ButtonEditPage title="Submit" style={{ marginLeft: "45%" }} /> */}
      <React.Fragment>
        <Button
          variant="outlined"
          onClick={handleClickOpen}
          sx={{
            color: "#FFFFFF",
            backgroundColor: "#185873",
            marginLeft: "45%",
            "&:hover": {
              backgroundColor: "#185887",
            },
          }}
        >
          SUBMIT
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to submit the report? "}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              If you submit it, you cannot edit it, as the report will now be
              accessible to the HOD of your department.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              <ButtonEditPage title="Cancel" />
            </Button>
            <Button onClick={handleClose} autoFocus>
              <ButtonEditPage title="Submit" />
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </div>
  );
}
export default SectionDisplayPage;
