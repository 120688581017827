import React from "react";
import Logout from "./Logout.js";
import "./NavbarDashboard.css";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext.js";
import { useContext } from "react";
import { Typography } from "@mui/material";

function NavbarLogin() {
  const navigation = useNavigate();
  const { dispatch } = useContext(AuthContext);
  const logoutFunc = () => {
    dispatch({ type: "LOGOUT" });
    localStorage.setItem("jwt", null);
    localStorage.setItem("role", null);
    navigation("/");
  };
  return (
    <div className="navbar">
      <div className="logo">
        <Typography
          sx={{
            fontFamily: "Montserrat, sans-serif",
            fontSize: "25px",
            fontWeight: "bold",
          }}
        >
          Harbinger
        </Typography>
      </div>
      <div className="logout">
        <Typography
          style={{
            marginRight: "30px",
            marginLeft: "-20px",
            marginTop: "5px",
            color: "#FFFFFF",
          }}
        >
          {localStorage.getItem("employeeId")}
        </Typography>
        <Logout onLogout={logoutFunc} />
      </div>
    </div>
  );
}

export default NavbarLogin;
