import NavbarDashboard from "../components/navbarDashboard/NavbarDashboard";
import * as React from "react";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";
import YearSelect from "../components/YearSelector/YearSelect";
import CardDashboard from "../components/CardDisplay/CardDashBoard";

const AdminPageReport = () => {
  const quarters = {
    "JAN-FEB-MAR": "1",
    "APR-MAY-JUN": "2",
    "JUL-AUG-SEP": "3",
    "OCT-NOV-DEC": "4",
  };
  const quarter = ["JAN-FEB-MAR", "APR-MAY-JUN", "JUL-AUG-SEP", "OCT-NOV-DEC"];
  const [reportData, setReportData] = React.useState([]);
  const [year, setYear] = React.useState(new Date().getFullYear().toString());
  const updateYear = (value) => {
    setYear(value);
  };
  React.useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt")} `,
      },
    };
    fetch(
      `https://harbinger-dev.amcspsgtech.in/api/admin/report/${year}/JAN-FEB-MAR`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setReportData(data);
        console.log(data);
      });
  }, []);
  return (
    <div>
      <NavbarDashboard />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: "7%",
          marginBottom: "-11%",
          marginRight: "5%",
        }}
      >
        <YearSelect yearUpdate={updateYear} />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100vh",
        }}
      >
        <div style={{ marginLeft: "15%" }}>
          <Typography
            variant="h4"
            component="div"
            sx={{
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "bold",
              color: "#FFFFFF",
            }}
          >
            QUARTER
          </Typography>
          <Typography
            variant="h3"
            component="div"
            sx={{
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "bold",
              color: "#79DCFF",
              textTransform: "lowercase",
              "&:first-letter": {
                textTransform: "capitalize", // Capitalize only the first letter
              },
            }}
          >
            SELECTOR
          </Typography>
        </div>
        <div style={{ marginRight: "20%", marginTop: "4%" }}>
          <Stack spacing={3}>
            <Stack direction="row" spacing={2}>
              {reportData ? (
                <>
                  <CardDashboard
                    currentYear={year}
                    id={reportData[0] && reportData[0].report ? reportData[0].report.id : null}
                    index={1}
                    stage={"Closed"
                    }
                    currentQuarter={"JAN-FEB-MAR"}
                    isEdit={
                      false
                    }
                    isDownload={false
                    }
                  />
                  <CardDashboard
                    // currentYear={year}
                    // id={reportData[1].report ? reportData[1].report.id : null}
                    // index={quarters[reportData[1].quarter]}
                    // stage={
                    //   reportData[0] && reportData[1].report
                    //     ? reportData[1].report.stage ===
                    //       "ACTION_FROM_REPRESENTATIVE"
                    //       ? "New"
                    //       : reportData[1].report.stage === "ACTION_FROM_HOD"
                    //       ? "Awaiting"
                    //       : reportData[1].report.stage === "ACTION_COMPLETED"
                    //       ? "Completed"
                    //       : null
                    //     : "Closed"
                    // }
                    // currentQuarter={reportData[1].quarter}
                    // isEdit={
                    //   reportData[1].report !== null &&
                    //   reportData[1].report.stage ===
                    //     "ACTION_FROM_REPRESENTATIVE"
                    //     ? true
                    //     : false
                    // }
                    // isDownload={
                    //   reportData[1].report !== null &&
                    //   reportData[1].report.stage === "ACTION_COMPLETED"
                    //     ? true
                    //     : false
                    // }
                    currentYear={year}
                    id={reportData[0] && reportData[0].report ? reportData[0].report.id : null}
                    index={2}
                    stage={"Closed"
                    }
                    currentQuarter={"APR-MAY-JUN"}
                    isEdit={
                      false
                    }
                    isDownload={false
                    }
                  />
                </>
              ) : null}
            </Stack>
            <Stack direction="row" spacing={2}>
              {reportData ? (
                <>
                  <CardDashboard
                    // currentYear={year}
                    // id={reportData[2].report ? reportData[2].report.id : null}
                    // index={quarters[reportData[2].quarter]}
                    // stage={
                    //   reportData[0] &&  reportData[2].report
                    //     ? reportData[2].report.stage ===
                    //       "ACTION_FROM_REPRESENTATIVE"
                    //       ? "New"
                    //       : reportData[2].report.stage === "ACTION_FROM_HOD"
                    //       ? "Awaiting"
                    //       : reportData[2].report.stage === "ACTION_COMPLETED"
                    //       ? "Completed"
                    //       : null
                    //     : "Closed"
                    // }
                    // currentQuarter={reportData[2].quarter}
                    // isEdit={
                    //   reportData[2].report !== null &&
                    //   reportData[2].report.stage ===
                    //     "ACTION_FROM_REPRESENTATIVE"
                    //     ? true
                    //     : false
                    // }
                    // isDownload={
                    //   reportData[2].report !== null &&
                    //   reportData[2].report.stage === "ACTION_COMPLETED"
                    //     ? true
                    //     : false
                    // }
                    currentYear={year}
                    id={reportData[0] && reportData[0].report ? reportData[0].report.id : null}
                    index={3}
                    stage={"New"
                    }
                    currentQuarter={"JUL-AUG-SEP"}
                    isEdit={
                      true
                    }
                    isDownload={true
                    }
                  />
                  <CardDashboard
                    // currentYear={year}
                    // id={reportData[3].report ? reportData[3].report.id : null}
                    // index={quarters[reportData[3].quarter]}
                    // stage={
                    //   reportData[0] && reportData[3].report
                    //     ? reportData[3].report.stage ===
                    //       "ACTION_FROM_REPRESENTATIVE"
                    //       ? "New"
                    //       : reportData[3].report.stage === "ACTION_FROM_HOD"
                    //       ? "Awaiting"
                    //       : reportData[3].report.stage === "ACTION_COMPLETED"
                    //       ? "Completed"
                    //       : null
                    //     : "Closed"
                    // }
                    // currentQuarter={reportData[3]}
                    // isEdit={
                    //   reportData[3].report !== null ? true : false
                    //   // reportData[3].report.stage ===
                    //   //   "ACTION_FROM_REPRESENTATIVE"
                    //   //   ? true
                    //   //   : false
                    // }
                    // isDownload={
                    //   reportData[3].report !== null &&
                    //   reportData[3].report.stage === "ACTION_COMPLETED"
                    //     ? true
                    //     : false
                    // }
                    currentYear={year}
                    id={reportData[0] && reportData[0].report ? reportData[0].report.id : null}
                    index={4}
                    stage={"Closed"
                    }
                    currentQuarter={"OCT-NOV-DEC"}
                    isEdit={
                      false
                    }
                    isDownload={false
                    }
                  />
                </>
              ) : null}
            </Stack>
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default AdminPageReport;
