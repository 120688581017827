import ReportCard from "../components/CardHod/CardHod";
import NavbarDashboard from "../components/navbarDashboard/NavbarDashboard";
import Searchbar from "../components/SearchBar/SearchBar";
import * as React from "react";
import { useParams, useNavigate } from "react-router-dom";
import ButtonEditPage from "../components/ButtonEditPage/ButtonEditPage";
import SectionDisplayCard from "../components/SectionDisplayCard/SectionDisplayCard";
import { Stack } from "@mui/material";
function HodEditPage() {
  /*  
    const [reportData,setReportData] = React.useState(null)
    React.useEffect(() => {
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwt")} `,
          },
        };
        fetch("https://harbinger-dev.amcspsgtech.in/api/report", requestOptions)
          .then((response) => response.json())
          .then((data) => {
            setReportData(data)
          })
        },[])
    return(
        <div>
        <NavbarDashboard/>
        
        <div style={{marginTop:"5.5em"}}>
        {/* <Searchbar /> */

  /*{ reportData && reportData.map((item,id)=>(
          <>
          {item.stage === "ACTION_FROM_HOD" ?<ReportCard key = {id} status={item.stage} current="true" reportId={item.id}/>:<ReportCard key = {id} status={item.stage} current="false" reportId={item.id}/>}
          </>
        ))}
        {/* <ReportCard status="Declined" current="true"/>
        <ReportCard status="Accepted" current="false"/>
        <ReportCard status="Accepted" current="false"/>
        <ReportCard status="Accepted" current="false"/>
        <ReportCard status="Accepted" current="false"/> }
        </div>
        </div>
    )
    */
  const { id } = useParams();
  const {currentYear} = useParams();
  const {currentQuarter} = useParams();
  // const cardData = [
  //   {
  //     title: "Cover Story",
  //     content:
  //       "Break through event/anything that will improve visibility of PSG Brand/Ideas...",
  //   },
  //   {
  //     title: "Focus Articles",
  //     content:
  //       "Institution - Innovation/ Research/MOU/Ranking/Achievement of Department, Head & Faculty: Student/ Alumni: Special Events/CSR activity/ any other.",
  //   },
  //   {
  //     title: "Workshops",
  //     content:
  //       "Seminars/ Conferences/Training Programs conducted (Write up containing title,dates, organizers name & designation, who sponsored the program, guest details, topic, number& nature of target audience)",
  //   },
  //   {
  //     title: "Paper Presentations",
  //     content:
  //       "Significant in-person presentations in institutes of international/national repute by faculty and students",
  //   },
  //   {
  //     title: "Research Initiatives/Project grants",
  //     content:
  //       "Title, details about the project, name & designation of investigator/s, duration, sanctioned amount, date and agency sanctioning the fund.One or two lines about the objective of the project can also be included.",
  //   },
  //   {
  //     title: "Awareness programs",
  //     content:
  //       "NSS/NCC/ CSR Activities(Like temple cleaning, school cleaning, projects taken up by individual institutions, departments, students. Include details like how long is the project, activities done, collaborations, outcomes, any associations, outcomes etc.)",
  //   },
  //   {
  //     title: "Awards/ Achievements",
  //     content:
  //       "institution wise/student/ faculty with complete details – when, where, who, date [Note: Include only significant recognitions/awards received at the International/ National level]",
  //   },
  //   {
  //     title: "Alumni events",
  //     content: "Details about the alumni events conducted",
  //   },
  //   {
  //     title: "Student activities and Achievements",
  //     content:
  //       "(Student participation in major competitions and their achievements with complete details)",
  //   },
  //   {
  //     title: "International Programs",
  //     content:
  //       "Global Focus/ exchange programs(Visits of faculty/ Heads, International speakers)",
  //   },
  //   {
  //     title: "Sports",
  //     content:
  //       "Sports (Only national & international participation with complete details & photograph)",
  //   },
  // ];
  const cardData = [
    {
      title: "Cover Story",
      content:
        "Break through event/anything that will improve visibility of PSG Brand/Ideas...",
    },
    {
      title: "Focus Articles",
      content:
        "Institution - Innovation/ Research/MOU/Ranking/Achievement of Department, Head & Faculty: Student/ Alumni: Special Events/CSR activity/ any other.",
    },
    {
      title: "Workshops",
      content:
        "Seminars/ Conferences/Training Programs conducted (Write up containing title,dates, organizers name & designation, who sponsored the program, guest details, topic, number& nature of target audience)",
    },
    {
      title: "Paper Presentations",
      content:
        "Significant in-person presentations in institutes of international/national repute by faculty and students",
    },
    {
      title: "Research Initiatives",
      content:
        "Title, details about the project, name & designation of investigator/s, duration, sanctioned amount, date and agency sanctioning the fund.One or two lines about the objective of the project can also be included.",
    },
    {
      title: "Awareness programs",
      content:
        "NSS/NCC/ CSR Activities(Like temple cleaning, school cleaning, projects taken up by individual institutions, departments, students. Include details like how long is the project, activities done, collaborations, outcomes, any associations, outcomes etc.)",
    },
    {
      title: "Achievements",
      content:
        "institution wise/student/ faculty with complete details – when, where, who, date [Note: Include only significant recognitions/awards received at the International/ National level]",
    },
    {
      title: "Alumni events",
      content: "Details about the alumni events conducted",
    },
    {
      title: "Student activities and Achievements",
      content:
        "(Student participation in major competitions and their achievements with complete details)",
    },
    {
      title: "International Programs",
      content:
        "Global Focus/ exchange programs(Visits of faculty/ Heads, International speakers)",
    },
    // {
    //   title: "Sports",
    //   content:
    //     "Sports (Only national & international participation with complete details & photograph)",
    // },
  ];
  const navigate = useNavigate();
  const handleClick = (title) => {
    navigate(`/hodQuillEdit/${id}/${currentYear}/${currentQuarter}/${title}`);
  };

  return (
    <div>
      <NavbarDashboard />
      <ButtonEditPage
        title="Progress"
        style={{ marginLeft: "5%", marginTop: "7%" }}
      />
      <div className="grid-container" style={{ marginTop: "1%" }}>
        {cardData.map((card, index) => (
          <SectionDisplayCard
            key={index}
            title={card.title}
            content={card.content}
            //onClickCard={handleClick}
            onClickCard={() => handleClick(card.title)}
          />
        ))}
      </div>
      <div  style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "20px",
          }}>
          <ButtonEditPage title="Actions" />
          {/* <ButtonEditPage title="Submit" /> */}
      </div>
    </div>
  );
}

export default HodEditPage;
