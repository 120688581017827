/*import "./ButtonEditPage.css";
import ProgressBar from "../ProgressBar/ProgressBar";
import { useState } from "react";
const ButtonEditPage = (props) => {
  const [isProgressBarOpen, setIisProgressBarOpen] = useState(false);
  const openProgressBar = () => {
    setIisProgressBarOpen(true);
  };
  const closeProgressBar = () => {
    setIisProgressBarOpen(false);
  };
  const handleProgressBarClick = () => {
    closeProgressBar();
  };

  return (
    <>
      <div className="button-cls" style={props.style} onClick={openProgressBar}>
        {isProgressBarOpen && <ProgressBar onClick={handleProgressBarClick} />}
        {props.title}
      </div>
    </>
  );
};
export default ButtonEditPage;*/
import React, { useState } from "react";
import "./ButtonEditPage.css";
import ProgressBar from "../ProgressBar/ProgressBar";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ButtonCardHOD from "../buttonCardHOD/buttonCardHOD";

const ButtonEditPage = (props) => {
  const { id } = useParams();
  const navigation = useNavigate();
  const [hodFlag, setHodFlag] = React.useState(null);
  const [isProgressBarOpen, setIsProgressBarOpen] = useState(false);
  const onSubmit = () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt")} `,
      },
      body: JSON.stringify({ data: { comment: "" } }),
    };
    fetch(
      `https://harbinger-dev.amcspsgtech.in/api/report/${id}/submit`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (localStorage.getItem("role") === "REPRESENTATIVE") {
          navigation("/representative");
        } else if (localStorage.getItem("role") === "HOD") {
          navigation("/hod");
        }
      });
  };
  const toggleProgressBar = () => {
    if (props.title === "Progress") {
      setIsProgressBarOpen((prevState) => !prevState);
    } else if (props.title === "Submit") {
      onSubmit();
    } else if (props.title === "Actions") {
      setHodFlag(true);
    }
  };
  const closeAction = () => {
    setHodFlag(false);
  };

  return (
    <div>
      <div
        className="button-cls"
        style={props.style}
        onClick={toggleProgressBar}
      >
        {isProgressBarOpen && <ProgressBar onClose={toggleProgressBar} />}
        {props.title}
      </div>
      {hodFlag == true ? <ButtonCardHOD actionClose={closeAction} /> : null}
    </div>
  );
};

export default ButtonEditPage;
