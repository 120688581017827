import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import RepEditPage from "./pages/RepEditPage";
import HodEditPage from "./pages/HodEditPage";
import AdminEditPage from "./pages/AdminEditPage";
import RepPageReport from "./pages/RepPageReport";
import HodPageReport from "./pages/HodPageReport";
import AdminPageReport from "./pages/AdminPageReport";
import * as React from "react";
import { ReportContentContextProvider } from "./providers/reportContentProvider";
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext";
import { Login } from "@mui/icons-material";
import SectionDisplayPage from "./pages/SectionDisplayPage";
import HodEdit from "./pages/HodEdit";

function App() {
  const { user } = useContext(AuthContext);
  //console.log(user);
  return (
    <BrowserRouter>
      <Routes>
        {/*<Route exact path="/" element={user?<RepPageReport/>:<LoginPage />} />
        <Route
          exact path={user ? "/Rep":"/"}
          element={
            user ? <RepPage /> : <LoginPage />
          }
        />
        <Route
          path="/RepEdit/:id"
          element={
            //localStorage.getItem("jwt") !== null && localStorage.getItem("role")==="REPRESENTATIVE"? (
            user ? (
              <ReportContentContextProvider>
                <RepEditPage />
              </ReportContentContextProvider>
            ) : (
              <LoginPage />
            )
            //) : (
            // <LoginPage />
            //)
          }
        />
        <Route
          path="/Hod"
          element={
            //localStorage.getItem("jwt") !== null && localStorage.getItem("role")==="HOD"?
            user ? <HodPage /> : <LoginPage />
            //: <LoginPage />
          }
        />
        <Route
          path="/HodEdit/:id"
          element={
            //localStorage.getItem("jwt") !== null && localStorage.getItem("role")==="HOD"? (
            user ? <HodEditPage /> : <LoginPage />
            //) : (
            //<LoginPage />
            //)
          }
        />
        <Route
          path="/Admin"
          element={
            //localStorage.getItem("jwt") !== null && localStorage.getItem("role")==="ADMIN" ?
            user ? <AdminPage /> : <LoginPage />
            //: <LoginPage />
          }
        />
        <Route
          path="/AdminEdit/:id"
          element={
            // localStorage.getItem("jwt") !== null &&
            // localStorage.getItem("role") === "ADMIN"
            user ? <AdminEditPage /> : <LoginPage />
          }
        />
        <Route
          path={user?"/representative":"/"}
          element={user ? <RepPageReport /> : <LoginPage />}
        />
        <Route path={user?"/hod":"/"} element={user ? <HodPageReport /> : <LoginPage />} />
        <Route
          path={user?"/admin":"/"}
          element={user ? <AdminPageReport /> : <LoginPage />}
        /> */}
        <Route
          exact
          path="/"
          element={
            // localStorage.getItem("jwt") !== null &&
            // localStorage.getItem("role") === "REPRESENTATIVE"
            user === "REPRESENTATIVE" ? (
              <RepPageReport />
            ) : // localStorage.getItem("jwt") !== null &&
            // localStorage.getItem("role") === "HOD"
            user === "HOD" ? (
              <HodPageReport />
            ) : // localStorage.getItem("jwt") !== null &&
            // localStorage.getItem("role") === "ADMIN"
            user === "ADMIN" ? (
              <AdminPageReport />
            ) : (
              <LoginPage />
            )
          }
        />
        {/* <Route
          path="/HodPage"
          element={
            // localStorage.getItem("jwt") !== null &&
            // localStorage.getItem("role") === "HOD"
            user === "HOD" ? <HodPage /> : <LoginPage />
          }
        /> */}
        <Route
          path="/hodEdit/:id/:currentYear/:currentQuarter"
          element={
            // localStorage.getItem("jwt") !== null &&
            // localStorage.getItem("role") === "HOD"
            user === "HOD" ? <HodEditPage /> : <LoginPage />
          }
        />
        {/* <Route
          path="/Rep"
          element={
            // localStorage.getItem("jwt") !== null &&
            // localStorage.getItem("role") === "REPRESENTATIVE"
            user === "REPRESENTATIVE" ? <RepPage /> : <LoginPage />
          }
        /> */}
        <Route
          path="/sec/:id/:currentYear/:currentQuarter"
          element={
            // localStorage.getItem("jwt") !== null &&
            // localStorage.getItem("role") === "REPRESENTATIVE"
            user === "REPRESENTATIVE" ? <SectionDisplayPage /> : <LoginPage />
          }
        />
        <Route
          path="/representative"
          element={
            user === "REPRESENTATIVE" ? <RepPageReport /> : <LoginPage />
          }
        />
        <Route
          path="/hod"
          element={user === "HOD" ? <HodPageReport /> : <LoginPage />}
        />
        <Route
          path="/admin"
          element={user === "ADMIN" ? <AdminPageReport /> : <LoginPage />}
        />
        <Route
          path="/representativeEdit/:id/:currentYear/:currentQuarter/:title"
          element={
            // localStorage.getItem("jwt") !== null &&
            // localStorage.getItem("role") === "REPRESENTATIVE"
            user === "REPRESENTATIVE" ? (
              <ReportContentContextProvider>
                <RepEditPage />
              </ReportContentContextProvider>
            ) : (
              <LoginPage />
            )
          }
        />
        <Route
          path="/hodQuillEdit/:id/:currentYear/:currentQuarter/:title"
          element={
            // localStorage.getItem("jwt") !== null &&
            // localStorage.getItem("role") === "REPRESENTATIVE"
            user === "HOD" ? (
              <ReportContentContextProvider>
                <HodEdit />
              </ReportContentContextProvider>
            ) : (
              <LoginPage />
            )
          }
        />
        <Route
          path="/adminEdit/:currentYear/:currentQuarter"
          element={
            // localStorage.getItem("jwt") !== null &&
            // localStorage.getItem("role") === "ADMIN"
            user === "ADMIN" ? <AdminEditPage /> : <LoginPage />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
