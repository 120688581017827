import React from "react";
import "./LoginButton.css";
import { Typography } from "@mui/material";

const LoginButton = (props) => {
  return (
    <div style={{ marginTop: "20px" }}>
      <button onClick={props.submit} className="login-button">
        <Typography
          sx={{
            fontFamily: "Montserrat, sans-serif",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          {props.name}
        </Typography>
      </button>
    </div>
  );
};

export default LoginButton;
