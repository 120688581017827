import LoginCard from "../components/LoginCard/LoginCard";
import NavbarLogin from "../components/navbarLogin/NavbarLogin";
import "./LoginPage.css";
//import "../index.css";
function LoginPage() {
  return (
      <div className="login-page">
        <NavbarLogin />
        <LoginCard />
      </div>
  );
}

export default LoginPage;
