import * as React from "react";
import Button from "@mui/material/Button";
function Category(props) {
  return (
    <div>
      {props.currentlySelected === true ? (
        <Button
          sx={{
            backgroundColor: "#035088",
            border: "2px solid #035088",
            color: "#ffffff",
            width: "250px",
            marginBottom: "10px",
          }}
          variant="contained"
        >
          {props.category}
        </Button>
      ) : (
        <Button
          sx={{
            backgroundColor: "#E2EBFC",
            border: "2px solid #035088",
            color: "#035088",
            "&:hover": {
              backgroundColor: "#035088",
              boxShadow: "none",
              color: "#FFFFFF",
            },
            width: "250px",
            marginBottom: "10px",
          }}
          variant="contained"
          onClick={() => {
            props.handleClick(props.category);
          }}
        >
          {props.category}
        </Button>
      )}
    </div>
  );
}

export default Category;
