import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from "@mui/icons-material/Download";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { saveAs } from "file-saver";

const CardDashboard = (props) => {
  const navigation = useNavigate();
  const [currentStatus, setCurrentStatus] = React.useState(null);
  const [currentStage, setCurrentStage] = React.useState(null);
  const [comment, setComment] = React.useState(null);
  const handleDownload = async () => {
    const backendUrl = localStorage.getItem("role")==="ADMIN"?`https://harbinger-dev.amcspsgtech.in/api/admin/report/${props.currentYear}/${props.currentQuarter}/download`:`https://harbinger-dev.amcspsgtech.in/api/report/${props.currentYear}/${props.currentQuarter}/download`;
    axios
      .get(backendUrl, {
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt")} `,
        },
      })
      .then((response) => {
        // Create a Blob from the response data
        const blob = new Blob([response.data], { type: "application/zip" });

        // Trigger the download using FileSaver
        saveAs(blob, "downloaded.zip");
      })
      .catch((error) => {
        console.error("Error downloading zip file:", error);
      });
  };
  React.useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt")} `,
      },
    };
    fetch(
      `https://harbinger-dev.amcspsgtech.in/api/report/${props.id}/steps`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data[0]) {
          setCurrentStatus(data[0].status);
          if (data[0].comment) {
            setComment(data[0].comment);
            setCurrentStage(data[0].currentStage);
          }
        } else {
          setCurrentStatus("New");
        }
      });
  }, []);
  return (
    <Card
      sx={{
        minWidth: 275,
        backgroundColor:
          props.stage === "Closed" || props.stage === "Completed"
            ? "#14475D"
            : "#067094",
        boxShadow: "0 4px 6px #FFFFFF",
        transition: "transform 0.3s, box-shadow 0.3s", // Add transition for smooth effect
        "&:hover": {
          transform: "scale(1.05)", // Scale the card on hover
          boxShadow: "0 8px 12px #FFFFFF", // Increase the box shadow on hover
        },
      }}
      // onClick={() => {
      //   if (localStorage.getItem("role") === "ADMIN") {
      //     navigation(`/AdminEdit/${props.currentYear}/${props.currentQuarter}`);
      //   }
      // }}
    >
      <CardContent>
        <Typography
          variant="h5"
          component="div"
          sx={{
            fontFamily: "Montserrat, sans-serif",
            textTransform: "lowercase",
            "&:first-letter": {
              textTransform: "capitalize", // Capitalize only the first letter
            },
            fontWeight: "bold",
            color: "#FFFFFF",
          }}
        >
          QUARTER
        </Typography>
        <Typography
          variant="h1"
          sx={{
            mb: 1.5,
            fontFamily: "Montserrat, sans-serif",
            fontWeight: "bold",
            background: "linear-gradient(to bottom, #2B596D,#FFFFFF)",
            WebkitBackgroundClip: "text",
            color: "transparent",
            display: "inline-block",
          }}
          color="text.secondary"
        >
          0{props.index}
        </Typography>
        <Stack direction="row" spacing={10}>
          <Typography
            variant="body2"
            sx={{
              fontFamily: "Montserrat, sans-serif",
              color: "#FFFFFF",
            }}
          >
            {props.stage === "Awaiting" ? currentStatus : props.stage}
            <br />
          </Typography>
          <Stack spacing={1}>
            <Typography
              variant="body2"
              sx={{
                fontFamily: "Montserrat, sans-serif",
                color: "#FFFFFF",
              }}
            >
              {props.currentQuarter}
            </Typography>
            <Stack direction="row" spacing={3}>
              {props.isEdit ? (
                <Box
                  sx={{
                    border: 1,
                    borderRadius: "3px",
                    padding: "2px",
                    borderColor: "#FFFFFF",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (localStorage.getItem("role") === "REPRESENTATIVE") {
                      navigation(
                        `/sec/${props.id}/${props.currentYear}/${props.currentQuarter}`
                      );
                    } else if (localStorage.getItem("role") === "HOD") {
                      navigation(
                        `/hodEdit/${props.id}/${props.currentYear}/${props.currentQuarter}`
                      );
                    } else if (localStorage.getItem("role") === "ADMIN") {
                      navigation(
                        `/AdminEdit/${props.currentYear}/${props.currentQuarter}`
                      );
                    }
                  }}
                >
                  <EditIcon style={{ color: "#FFFFFF" }} />
                </Box>
              ) : (
                <Box
                  sx={{
                    border: 1,
                    borderRadius: "3px",
                    padding: "2px",
                    borderColor: "#4C4E4E",
                  }}
                >
                  <EditIcon style={{ color: "#4C4E4E" }} />
                </Box>
              )}
              {props.isDownload ? (
                <Box
                  sx={{
                    border: 1,
                    borderRadius: "3px",
                    padding: "2px",
                    borderColor: "#FFFFFF",
                    cursor: "pointer",
                  }}
                  onClick={handleDownload}
                >
                  <DownloadIcon style={{ color: "#FFFFFF" }} />
                </Box>
              ) : (
                <Box
                  sx={{
                    border: 1,
                    borderRadius: "3px",
                    padding: "2px",
                    borderColor: "#4C4E4E",
                  }}
                >
                  <DownloadIcon style={{ color: "#4C4E4E" }} />
                </Box>
              )}
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default CardDashboard;
