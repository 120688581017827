import React from "react";
import "./NavbarLogin.css";
import { Typography } from "@mui/material";

function NavbarLogin() {
  return (
    <div className="navbar">
      <div className="logo">
        <Typography
          sx={{
            fontFamily: "Montserrat, sans-serif",
            fontSize: "25px",
            fontWeight: "bold",
          }}
        >
          Harbinger
        </Typography>
      </div>
    </div>
  );
}

export default NavbarLogin;
