import React, { useContext, useState, useEffect } from "react";
import "./LoginCard.css";
import LoginButton from "../loginButton/LoginButton.js";
import TextField from "@mui/material/TextField";
import LoginImage from "../../images/Login_image.png";
import Card from "@mui/material/Card";
import { useNavigate } from "react-router";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Input from "@mui/material/Input";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import { AuthContext } from "../../context/AuthContext.js";

const LoginCard = () => {
  const { dispatch } = useContext(AuthContext);
  const [userError, setUserError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [Username, setUsername] = useState("");
  const onUsernameChange = (event) => {
    const capitalizedValue = event.target.value.toUpperCase();
    setUsername(capitalizedValue);
    console.log(Username);
  };

  const [Password, setPassword] = useState("");
  const onPasswordChange = (event) => {
    const capitalizedValue = event.target.value.toUpperCase();
    setPassword(capitalizedValue);
    console.log(Password);
  };
  const [userDetails, setUserDetails] = useState("");
  const navigation = useNavigate();
  const submitFunction = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ username: Username, password: Password }),
    };
    fetch("https://harbinger-dev.amcspsgtech.in/api/auth/login", requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setUserDetails(data);
        if (data) {
          localStorage.setItem("jwt", data.token);
          localStorage.setItem("role", data.role);
          localStorage.setItem("employeeId", data.employeeId);
          dispatch({ type: "LOGIN_SUCCESS", payload: data.role });
          if (data.role === "HOD") {
            navigation("/hod");
          } else if (data.role === "REPRESENTATIVE") {
            navigation("/representative");
          } else if (data.role === "ADMIN") {
            navigation("/admin");
          }
        }
      })
      .catch((error) => {
        console.error("Error during login:", error);
        setUserError(true);
        setPasswordError(true);
        // Handle the error appropriately, e.g., show an error message to the user
      });
  };

  return (
    <>
      <div className="login">
        <Card variant="outlined" className="form">
          <TextField
            error={userError}
            required
            value={Username}
            id="standard-basic"
            label="Username"
            variant="standard"
            onChange={onUsernameChange}
            sx={{ marginBottom: "25px" }}
          />
          {/* <TextField
            id="standard-basic"
            label="Password"
            variant="standard"
            type="password"
            onChange={onPasswordChange}
            sx={{ marginBottom: "32px" }}
          /> */}
          <FormControl
            sx={{ m: 1, width: "25ch" }}
            error={passwordError}
            required
            variant="standard"
          >
            <InputLabel htmlFor="standard-adornment-password">
              Password
            </InputLabel>
            <Input
              value={Password}
              onChange={onPasswordChange}
              id="standard-adornment-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <LoginButton name="Log In" submit={submitFunction} />
        </Card>
        <div className="login-img">
          <img src={LoginImage} alt="Login-Image" width="500" height="500" />
        </div>
      </div>
    </>
  );
};

export default LoginCard;
