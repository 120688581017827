import React from "react";
import "./AdminDepCard.css";
import Card from "@mui/material/Card";
import { CardContent } from "@mui/material";
import { Typography } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { useState } from "react";

function AdminDepCard(props) {
  const [isCalenderOpen, setIsCalenderOpen] = useState(false); // State to manage pop-up visibility
  const [selectedDate, setSelectedDate] = useState(null); // State to manage selected date
  const [showText,setShowText] = useState(false);
  const handleCalender = () => {
    setIsCalenderOpen(true);
  };

  const handleSubmit = () => {
    setIsCalenderOpen(false);
    props.onSubmit(selectedDate,props.title);
    setShowText(true);
    setTimeout(()=>(setShowText(false)),5000)
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <div className="card">
      <div className="card-content">
        <div className="card-title">{props.title}</div>
        <p className="card-text">{props.content}</p>
        <div >
          <CalendarMonthIcon onClick={handleCalender} style={{ cursor: "pointer" }}/>
          {isCalenderOpen && (
            <div className="popup-container">
              <div className="popup">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateCalendar
                    value={selectedDate}
                    onChange={handleDateChange}
                  />
                </LocalizationProvider>
                <button onClick={handleSubmit}>Submit</button>
              </div>
            </div>
          )}
        </div>
        {showText&&<p className="card-text">Deadline extended!</p>}
      </div>
    </div>
  );
}

export default AdminDepCard;

/*
    <Card sx={{ margin: "10px", position: 'relative' }}>
    <CardContent
      style={{
        justifyContent: "space-between",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div className={props.status==="Completed"? 'inactive':'active'}>
      </div>
      <div 
      style={{
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          marginLeft:"1rem"
        }}>
      <div>
        <Typography
          sx={{ fontFamily: "Junge", fontSize: "2.5rem", color: "#035088" }}
        >
         AMCS
        </Typography>
      </div>
      </div>
      <div
        style={{
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        {props.status==='Completed'?<Typography
          sx={{ fontFamily: "Junge", fontSize: "1.5rem", color: "#035088", marginRight:"1rem"}}
        >
          Submitted on dd/mm/yy
        </Typography> : <CalendarMonthIcon sx={{width: "35px", height: "35px", color: "#035088", marginRight:"1rem"}} />}
      </div>
    </CardContent>
  </Card>
  */
