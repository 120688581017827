import React from "react";
import "./SectionDisplayCard.css";

const SectionDisplayCard = (props) => {
  return (
    <div
      className={
        localStorage.getItem("role") === "REPRESENTATIVE"
          ? "card-rep"
          : "card-hod"
      }
      onClick={() => props.onClickCard()}
    >
      <div className="card-content">
        <h2 className="card-title">{props.title}</h2>
        <p className="card-text">{props.content}</p>
      </div>
    </div>
  );
};

export default SectionDisplayCard;
