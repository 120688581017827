import { createContext, useEffect, useState } from "react";

export const ReportContentContext = createContext();

const initialReportContentState = {
  coverStory: "",
  focusArticles: "",
  worshops: "",
  paperPresentations: "",
  researchInitiatives: "",
  awarnessProgram: "",
  awards: "",
  alumniEvents: "",
  studentActivities: "",
  exchangePrograms: "",
  sports: "",
};

export const COVER_STORY = "coverStory";
export const FOCUS_ARTICLES = "focusArticles";
export const WORKSHOP = "worshops";
export const PAPER_PRESENTATIONS = "paperPresentations";
export const RESEARCH_INITIATIVES = "researchInitiatives";
export const AWARNESS_PROGRAM = "awarnessProgram";
export const AWARDS = "awards";
export const ALUMNI_EVENTS = "alumniEvents";
export const STUDENT_ACTIVITIES = "studentActivities";
export const EXCHANGE_PROGRAMS = "exchangePrograms";
export const SPORTS = "sports";

export const ReportContentContextProvider = ({ children }) => {
  const [content, setContent] = useState(initialReportContentState);

  const setSubTitle = (subTitle, subTitleContent) => {
    setContent((content) => {
      const newContent = {
        ...content,
      };
      newContent[subTitle] = subTitleContent;
      return newContent;
    });
  };

  const getSubTitle = (subTitle) => {
    return content[subTitle];
  };

  useEffect(() => {
    // Get the content from the backend and use setContent to set the same
    console.log(content);
  }, [content]);

  return (
    <ReportContentContext.Provider
      value={{ content, setContent, setSubTitle, getSubTitle }}
    >
      {children}
    </ReportContentContext.Provider>
  );
};
